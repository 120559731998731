var swiperHelper;

var sliderobj;

function swiperHelper(){

    let me = this;
    me.logo;
    me.swipers = {};
    
    me.init = (offset) => {
        me.swipers = document.querySelectorAll(".swiper-container");

        me.swipers.forEach((swiper) => {
            me.initSwiper(swiper);
        });
    }

    me.initSwiper = (target) => {
        let swiper = new Swiper(target, {
            // Optional parameters
            loop: true,
            autoHeight: true,
            // Enable lazy loading
            preloadImages: false,
            lazy: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
        
            // Navigation arrows
            navigation: {
                nextEl: target.parentElement.querySelector('.swiper-button-next'),
                prevEl: target.parentElement.querySelector('.swiper-button-prev'),
            },
            on: {
                afterInit: function () {
                    me.swiperNumber(this);
                    me.swiperNext(this, 'init');
                },
                onLazyImageReady: function () {
                    updateAutoHeight();
                },
                slideChangeTransitionStart: function () {
                    me.swiperNext(this, 'change');
                },
                slideChangeTransitionEnd: function () {
                    me.swiperNumber(this);
                    if(!this.loaded) { // per evitare di mandare gli eventi all'init
                        this.loaded = true;
                    } else {
                        // console.log(this.realIndex + 1 + ' transition end');
                        // forzo la pageview
                        // ga('send', 'pageview', location.pathname+'?gallery='+ (this.realIndex + 1) );
                        gtag('event', 'page_view', {
                            'page_path': location.pathname+'?gallery='+ (this.realIndex + 1),
                        })
                        // mando info a MM di fare refresh
                        if(typeof MediamondTagRefresh !== "undefined" && typeof mm_box1 !== "undefined"){
                            MediamondTagRefresh();
                        }
                    }
                },
            },
        });
        return swiper;
    };

    me.swiperNumber = (slider) => {
        // funzione per sapere in che slide siamo nel container
        // if (slider.el.classList.contains('ic21-opening-swiper')) {
        //     // siamo nello swiper home
            let currentslide = slider.el.querySelector(".swiper-slide-active");
            let currentindex = currentslide.dataset.swiperSlideIndex;
            slider.el.dataset.index = currentindex;
            

        // }
    }
    me.swiperNext = (slider, position) => {
        // per aggiornare l'altezza del bottone next
        let nextbutton = slider.el.querySelector(".swiper-button-next");
        let prevbutton = slider.el.querySelector(".swiper-button-prev");
        let currentdesc = slider.el.querySelector(".swiper-slide-active .ic21-content-gallery .ic21-content-image-desc");
        if (nextbutton && currentdesc) {
            nextbutton.style.bottom = currentdesc.clientHeight + "px";
        }
        if (prevbutton && currentdesc) {
            // ricalcolo in che slide siamo
            let currentslide = slider.el.querySelector(".swiper-slide-active");
            let currentindex = currentslide.dataset.swiperSlideIndex;
            slider.el.dataset.index = currentindex;
            if(slider.el.dataset.index > 0) {
                prevbutton.classList.add('active');
            }
            prevbutton.style.bottom = currentdesc.clientHeight + "px";
        }
    }

}

// var swiperHelper;

// function swiperHelper(){
//     let me = this;
//     me.logo;
//     me.swipers = {};

//     me.init = (offset) => {
//         me.logo = document.getElementById("dm20-header-logo-img");
//         me.breakpoint1000 = window.matchMedia('(min-width:1000px)');
//         me.breakpoint1200 = window.matchMedia('(min-width:1200px)');

//         me.offset = 0;
//         if(offset){
//             me.offset = offset;
//         }

//         me.setupSwipers();
//         me.initSwipers();

//         window.addEventListener('MMSkinGo', () => {
//             me.updateSwipers();
//         }, false);

//         me.breakpoint1000.addListener(me.breakpointChecker);
//         me.breakpoint1200.addListener(me.breakpointChecker);
// 	};

//     me.breakpointChecker = () => {
//         setTimeout(() => {me.updateSwipers();}, 300);
//     };

//     me.setupSwipers = () => {
//         let swiperContainers = document.querySelectorAll(".dm20-swiper-container");

//         for(let i=0; i<swiperContainers.length; i++){
//             let target = swiperContainers[i],
//                 name = target.dataset.swipername,
//                 type = target.dataset.swipertype,
//                 load = target.dataset.swiperload
//                 callback = target.dataset.swipercallback || false;

//             me.swipers[name] = {
//                 "target": target,
//                 "type": type,
//                 "load": load,
// 				"callback": callback
//             };
//         }

//         me.setSlides();
//     };

//     me.setSlides = () => {
//         for(let name in me.swipers){
//             let type = me.swipers[name].type;
//             let load = me.swipers[name].load;

//             switch(type){
//                 case "main":
//                     me.setMainSlides(name);
//                 break;

//                 case "default":
//                     me.setDefaultSlides(name);
//                 break;
//             }
//         }
//     };

//     me.setDefaultSlides = (name) => {
//         me.swipers[name]["slides"] = me.getSlidesFromDOM(name);
//     };

//     me.getSlidesFromDOM = (name) => {
//         var slides = [];
//         let target = me.swipers[name].target;
//         let slidesDOM = [].slice.call(target.querySelectorAll(":scope > .dm20-swiper-slide"));
//         for(var i=0; i<slidesDOM.length; i++){
//             var slide = slidesDOM[i];
//             slides.push(slide.outerHTML);
//         }

//         return slides;
//     };

//     me.initSwipers = () => {
//         for(var name in me.swipers){
//             if(me.swipers[name].load == "default"){
//                 var target = me.swipers[name].target;
//                 me.swipers[name]["swiper"] = me.initSwiper(target);
//             }
//         }

//         me.processLazySwipers();
//         window.addEventListener('scroll', function() {
// 			me.processLazySwipers();
// 		});
//     };

//     me.processLazySwipers = () => {
//         for(var name in me.swipers){
//             if(me.swipers[name].load == "lazy"){
//                 var target = me.swipers[name].target;
//                 if(me.toProcessLazySwipers(target) && !target.classList.contains('processed')){
//                     me.swipers[name]["swiper"] = me.initSwiper(target);
//                     target.classList.add('processed');
//                 }
//             }
//         }
//     };

//     me.toProcessLazySwipers = (element) => {
//         var rect = element.getBoundingClientRect();
//         var html = document.documentElement;
// 		return (
// 			(rect.top - me.offset) <= (window.innerHeight || html.clientHeight) && (rect.top + rect.height) >= 0
// 		);
//     };

//     me.setMainSlides = (name) =>{
//         let swiper = me.swipers[name];

// 		if(swiper.swiper === undefined){
//             swiper["swiper"] = me.initMainSwiper(swiper.target);
//             swiper.target.classList.add("dm20-swiper-dom-loaded");
//         }
//     };

//     me.initSwiper = (target, loop) => {
//         var loadedslide = false;
//         var swiper = new Swiper(target, {
//             breakpointsInverse: true,
//             navigation: {
//                 nextEl: target.parentNode.querySelectorAll(':scope > .dm20-arrow-button-next')
//             },
//             pagination: {
//                 el: target.parentNode.querySelector(':scope > .dm20-arrow-button-next .dm20-swiper-pagination-fraction'),
//                 type: 'fraction',
//             },
//             loop: target.dataset.swiperloop ? target.dataset.swiperloop : false,
//             slidesPerView: "auto",
// 			slidesPerGroup: 1,
//             calculateHeight:true,
//             autoHeight:true,
//             simulateTouch: false,
//             observeParents: true,
//             on: {
//                 slideChangeTransitionEnd: function (swiper) {
//                     if (loadedslide) {
//                         var realindex = parseInt(target.querySelector('.swiper-slide-active').dataset.swiperSlideIndex) + 1;

//                         let eventSlideChangeTransitionEnd = new CustomEvent("SwiperChange",{detail: {slide: "image_" + realindex}});
//                         document.dispatchEvent(eventSlideChangeTransitionEnd);
//                     }
//                     loadedslide = true;
//                 }
//             }
//         });

//         return swiper;
//     };

//     me.initMainSwiper = (target) => {
//         var isHome = target.dataset.ishome ? target.dataset.ishome : false;
//         let swiper = new Swiper(target, {
//             breakpointsInverse: true,
// 			speed: 300,
//             slideActiveClass: 'dm20-swiper-slide-active',
// 			autoplay: {
// 				delay: 6000,
// 				disableOnInteraction: false
// 			},
// 			navigation: {
//                 nextEl: target.parentNode.querySelectorAll(':scope > .dm20-arrow-button-next'),
//                 prevEl: target.parentNode.querySelectorAll(':scope > .dm20-arrow-button-prev')
//             },
//             loop: true,
// 			updateOnImagesReady: true,
//             slidesPerView: "auto",
//             simulateTouch: false,
//             breakpoints: {
//                 1000: {
// 					loop: true
//                 },
//                 1200: {
// 					loop: true
//                 }
//             },
//             on: {
//                 transitionStart: () => {
//                     if(isHome){
//                         let activeSlide = target.querySelector('.dm20-swiper-slide-active');
//                         let channel = activeSlide.dataset.channel;

//                         me.logo.setAttribute("class","");
//                         me.logo.classList.add("dm20-" + channel);
//                     }
//                 }
//             }
//         });

//         return swiper;
//     };

//     me.getSwipers = function(){
// 		return me.swipers;
// 	};

//     me.updateSwipers = () => {
//         for(const i in me.swipers){
//             me.swipers[i].swiper.update();
//         }
//     };
// }